@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.gradientHeader {
  /* width: 100%;
  height: 100%; */
  
  background: linear-gradient(to right, #FFFFFF, #FFD63A, #FFA912, #7287FE, #FD4755);

}

.DownloadappsectionGradient {
  background: linear-gradient(to right, #FFA912, #7287FE, #FD4755);

}

.footerGradient {
  background: linear-gradient(to right, #FAC600, #92A2FF);
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #FAC600, #92A2FF);

  backdrop-filter: blur(10px); 

  z-index: 9999;
}


.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 1s ease-in-out; /* Smooth transition */
  /* background-color: white; */
}

/* animatin color change para  */
.colorChange {
  animation: colorChange 4s infinite ; /* Change the duration or animation style as needed */
}

@keyframes colorChange {
  0% {
    color: red;
     /* Set the initial color here */
  }
  25% {
    color: #3490dc; /* Change this to the desired color */
    margin-top: 20px;
  }
  50% {
    color: #FAC600;
    /* transform: rotate(360deg); */
  }

  75% {
    color: #1531cd;
  }
  100% {
    color: chartreuse;
    /* font-size: 20px; */
    margin-bottom: 10px;
  }

}



/* Responsiveness of header */

@media screen and (max-width: 768px) {
  .headerResponsive {
    
    display: flex;
    justify-content: space-between;
  
  }

  .headerResponsive h1 {
    text-align: right;
  }
  .headerResponsive h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {

  .textRight {
    /* text-align: right; */
    display: flex ;
    justify-content: flex-end;
  }
  .headerResponsive {
    
    display: flex;
    flex-direction: column;
  
  }

  .headerResponsive h1 {
    text-align: right;
  }
  .headerResponsive h1 {
    font-size: 1.5rem;
  }

  /* Hero Section */

  .heroResponsive {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
  }
 
}